import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ServerResponse } from '@wellro/auxilary-models';
import {
  Customer,
  CustomerDocument,
  CustomerIdentity,
  CustomerIdentityDocument,
  UserRole,
  UserStatus,
} from '@wellro/models';
import { LoggerService } from '@wellro/utils';
import { ReplaySubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  // TODO:  The customer search is done in memory with an O(N) algorithm
  //        This data must be stored on secondary storage and the algorithm must be improved
  //        when the customer base is large (>1000) to avoid performance issues
  //        and to save firestore read costs

  private customerStreamSubscription?: Subscription;
  private customerStreamAvailabilitySubject = new ReplaySubject(1);

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private logger: LoggerService,
    private cache: CacheService
  ) {}

  getCustomerById(customerId: string) {
    const ref = this.firestore
      .collection<CustomerDocument>('Users')
      .doc(customerId);
    return ref.get().pipe(
      map((snapshot) => snapshot.data()),
      map((document) => this.getCustomerFromCustomerDocument(document))
    );
  }

  getCustomerIdentityById(customerId: string) {
    const ref = this.firestore
      .collection('Users')
      .doc(customerId)
      .collection('Profile')
      .doc<CustomerIdentityDocument>('Identity');
    return ref.get().pipe(
      map((snapshot) => snapshot.data()),
      map((document) => {
        const customerIdentity: CustomerIdentity = {
          address: document.address,
          nicNo: document.nicNo,
          user: document.user,
        };

        return customerIdentity;
      })
    );
  }

  getCustomers(
    count: number,
    sortField: 'name' = 'name',
    lastSortFieldValue?: string
  ) {
    const ref = this.firestore.collection<CustomerDocument>('Users', (ref) => {
      const query = ref
        .where('role', '==', UserRole.Customer)
        .orderBy(sortField);
      if (lastSortFieldValue) {
        return query.startAfter(lastSortFieldValue).limit(count);
      }

      return query.limit(count);
    });

    return ref.get().pipe(
      map((snapshot) => {
        return snapshot.docs
          .map((document) => document.data())
          .map((document) => this.getCustomerFromCustomerDocument(document));
      }),
      tap((users) => {
        for (const user of users) {
          this.cache.setItem(user.userId, user);
        }
      })
    );
  }

  getCustomersWithMobileNumberPrefix(
    mobileNumber: string,
    count: number,
    lastSortFieldValue?: string
  ) {
    const searchStartKey = `+94${this.formateMobileNumber(mobileNumber)}`;
    const searchEndKey = `${searchStartKey}~`;
    const ref = this.firestore.collection<CustomerDocument>('Users', (ref) => {
      const query = ref
        .where('role', '==', UserRole.Customer)
        .where('mobileNumber', '>=', searchStartKey)
        .where('mobileNumber', '<=', `${searchEndKey}~`)
        .orderBy('mobileNumber');
      if (lastSortFieldValue) {
        return query.startAfter(lastSortFieldValue).limit(count);
      }

      return query.limit(count);
    });

    return ref.get().pipe(
      map((snapshot) => {
        return snapshot.docs
          .map((document) => document.data())
          .map((document) => this.getCustomerFromCustomerDocument(document));
      }),
      tap((users) => {
        for (const user of users) {
          this.cache.setItem(user.userId, user);
        }
      })
    );
  }

  searchCustomerByMobileNumber(mobileNumber: string, limit?: number) {
    // format the mobile number
    // search in the dataset
    const searchStartKey = `+94${this.formateMobileNumber(mobileNumber)}`;
    const searchEndKey = `${searchStartKey}~`;

    this.logger.debug('Searching customer by mobile', {
      searchStartKey,
      searchEndKey,
    });
    return this.cacheCustomersStream().pipe(
      map(() => {
        const customers = this.cache.getItem<CustomerDocument[]>(
          'all-customers'
        );
        if (!customers) {
          throw new Error('Customers are not cached!');
        }

        return customers.filter((customer) => {
          return (
            customer.mobileNumber >= searchStartKey &&
            customer.mobileNumber < searchEndKey
          );
        });
      }),
      map((documents) => {
        return documents.sort((a, b) =>
          a.mobileNumber < b.mobileNumber ? -1 : 1
        );
      }),
      map((documents) => {
        if (limit) {
          return documents.slice(0, limit);
        }

        return documents;
      }),
      map((documents) => {
        return documents.map((document) =>
          this.getCustomerFromCustomerDocument(document)
        );
      })
    );
  }

  markMobileNumberAsVerified(customerId: string) {
    return this.http
      .post<ServerResponse<string>>(
        `/api/customers/${customerId}/mark-mobile-number-as-verified`,
        null
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          }
          this.logger.error(
            'Could not mark customer mobile number as verified',
            response.statusCode
          );
          throw Error(response.message);
        })
      );
  }

  private getCustomerFromCustomerDocument(document: CustomerDocument) {
    const customer: Customer = {
      userId: document.userId,
      channelingPartner: document.channelingPartner,
      claims: document.claims,
      dateOfBirth: document.dateOfBirth,
      allergies: document.allergies,
      disabled: document.disabled,
      email: document.email,
      gender: document.gender,
      mobileNumber: document.mobileNumber,
      name: document.name,
      pictureUrl: document.pictureUrl,
      role: document.role,
      status: document.status,
    };

    return customer;
  }

  private cacheCustomersStream() {
    if (
      this.customerStreamSubscription &&
      !this.customerStreamSubscription.closed
    ) {
      return this.customerStreamAvailabilitySubject.asObservable();
    }

    const ref = this.firestore.collection<CustomerDocument>('Users', (ref) =>
      ref
        .where('role', '==', UserRole.Customer)
        .where('disabled', '==', false)
        .where('status', '>=', UserStatus.Active)
    );

    this.logger.info('Subscribing to customer stream');
    this.customerStreamSubscription = ref
      .stateChanges(['added', 'modified', 'removed'])
      .subscribe((changes) => {
        let customerDocuments =
          this.cache.getItem<CustomerDocument[]>('all-customers') || [];

        if (changes.length > 1000) {
          this.logger.warn(
            'Stream size is big. Update the search mechanism to improve performance'
          );
        }

        for (const change of changes) {
          if (change.type === 'removed') {
            // remove item from cache
            customerDocuments = customerDocuments.filter(
              (customer) => customer.userId !== change.payload.doc.id
            );
            // this.cache.invalidateItem(`customer:${change.payload.doc.id}`);
          } else if (change.type === 'modified') {
            // update item from cache
            customerDocuments = customerDocuments.filter(
              (customer) => customer.userId !== change.payload.doc.id
            );
            customerDocuments.push(change.payload.doc.data());
            // this.cache.setItem(`customer:${change.payload.doc.id}`, change.payload.doc.data());
          } else {
            // add itom to cache
            customerDocuments.push(change.payload.doc.data());
          }
        }

        this.cache.setItem('all-customers', customerDocuments);

        if (!this.customerStreamAvailabilitySubject.closed) {
          this.customerStreamAvailabilitySubject.next(true);
          this.customerStreamAvailabilitySubject.complete();
        }
      });

    return this.customerStreamAvailabilitySubject.asObservable();
  }

  private formateMobileNumber(val: string) {
    const step_1 = val.replace(/\D/g, '');
    const step_2 = step_1.replace(/^0+/, '');
    const step_3 = step_2.replace(/^94/, '');
    const step_4 = step_3.replace(/^0+/, '');
    return step_4;
  }
}
