import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Carrier, CarrierDocument } from '@wellro/models';
import { map, switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarrierService {
  constructor(private firestore: AngularFirestore) {}

  createCarrier(name: string, email: string): Observable<Carrier> {
    // TODO: Move to backend
    const queryRef = this.firestore.collection<CarrierDocument>(
      'Carriers',
      (ref) => ref.where('email', '==', email)
    );
    const carrierRef = this.firestore
      .collection<CarrierDocument>('Carriers')
      .doc();
    const carrier: Carrier = {
      carrierId: carrierRef.ref.id,
      name: name,
      email: email,
    };
    return queryRef.get().pipe(
      switchMap((querySnapshot) => {
        if (!querySnapshot.empty) {
          throw new Error('There is already a user by this email');
        }

        return from(carrierRef.set(carrier));
      }),
      map(() => {
        return carrier;
      })
    );
  }

  getCarriers(
    count: number,
    sortField: 'email' | 'name' = 'email',
    lastSortFieldValue?: string
  ) {
    const ref = this.firestore.collection<CarrierDocument>(
      'Carriers',
      (ref) => {
        const query = ref.orderBy(sortField);
        if (lastSortFieldValue) {
          return query.startAfter(lastSortFieldValue).limit(count);
        }

        return query.limit(count);
      }
    );

    return ref.get().pipe(
      map((snapshot) => {
        return snapshot.docs
          .map((document) => document.data())
          .map((carrier) => carrier);
      })
    );
  }

  getAllCarriers(): Observable<Carrier[]> {
    const ref = this.firestore.collection<CarrierDocument>(
      'Carriers',
      (ref) => {
        return ref.orderBy('name');
      }
    );
    return ref.get().pipe(
      map((snapshot) => {
        return snapshot.docs
          .map((document) => document.data())
          .map((carrier) => carrier);
      })
    );
  }
}
